<template>
  <div class="login-container">

    <div class="bg1"></div>
    <div class="gyl">银行数据资产管理平台(BDAMP)
      <div class="gy2">Bank Data Asset Management Platform</div>
    </div>
    <el-form class="login-form" ref="form" :model="form">
      <div class="title-container">
        <h3 class="title">用户登录</h3>
      </div>    
      <el-form-item prop="username">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input v-model="form.username" placeholder="账号" autocomplete="on" type="text" />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input :key="passwordType" :type="passwordType" ref="password" v-model="form.password" placeholder="密码" name="password" auto-complete="on" @keyup.enter.native="confirmForm" />
        <span class="show-pwd" @click="showPwd">
          <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
        </span>
      </el-form-item>

      <el-button type="primary" @click="confirmForm" style="width: calc(100% - 40px); margin: 20px">登录</el-button>
    </el-form>
  </div>
</template>

<script>
// import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {
        username: 'admin',
        password: '123456'
      },
      defaultForm: {},

      passwordType: 'password'
    }
  },

  methods: {
    showPwd() {
      this.passwordType = this.passwordType === 'password' ? '' : 'password'
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    async confirmForm() {
      try {
        const res = await this.$api.execute('account.login', this.form)
        this.$store.commit('setAccountInfo', res);
        this.$message.success('登录成功');
        this.$router.push(this.$route.query.redirect || '/')
        this.$emit('success')
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  min-height: 100vh;
  overflow: hidden;

  background: url(../../assets/loginbg2.png) no-repeat;
  background-size: cover;

  .gyl {
    height: 237px;
    position: absolute;
    bottom: 0;
    margin: auto;

    font-size: 42px;
    // width: 550px;
    top: 20%;
    left: 15%;
    display: flex; 
    flex-direction: column; 
    align-items: center;

    color: #fff;

    .gy2 {
      color: #fff;
      margin-left: 0;
      font-size: 18px;
      text-align: center;
      margin-top: 10px;
    }
  }

  .bg1 {
    height: 237px;
    position: absolute;
    bottom: 0;
    margin: auto;

    width: 100%;
    background: #347ebc;
    opacity: .6;
    left: 0;
    top: 0;
    right: 0;
  }

  .login-form {
    width: 375px;
    height: 337px;
    background: #fff;
    position: absolute;
    left: 56%;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    -webkit-box-shadow: 0 0 50px #00000066;
    box-shadow: 0 0 50px #00000066;
    border-radius: 5px;

    .el-form-item {
      border-bottom: 0.0625rem solid #347ebc;
      color: #454545;
      margin: 10px 20px;

      .svg-container {
        padding: 6px 5px 6px 15px;
        color: #889aa4;
        vertical-align: middle;
        width: 30px;
        display: inline-block;
      }
      
      .show-pwd {
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 16px;
        color: #889aa4;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .el-input {
        display: inline-block;
        height: 47px;
        width: 85%;

        /deep/ .el-input__inner {
          background: transparent;
          border: 0;
          -webkit-appearance: none;
          border-radius: 0;
          padding: 12px 5px 12px 15px;
          color: #606266;
          height: 47px;
          caret-color: #666;
        }
      }
    }

    .title-container {
      position: relative;

      .title {
        width: 375px;
        height: 35px;
        color: #347ebc;
        font-size: 1.5rem;
        margin-top: 35px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}

</style>